import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import { TextEntry } from '@thg-commerce/gravity-theme'

export const ProductContainer = styled(Grid)<{
  horizontalAlignment?: HorizontalAlignment
}>`
  column-gap: ${spacing(2)};
  row-gap: ${spacing(3)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    column-gap: ${spacing(4)};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    row-gap: ${spacing(4)};
  } ;
`

export const Title = styled.h2<{
  size?: TextEntry
  alignment: HorizontalAlignment
  subtitle: boolean
}>`
  ${(props) =>
    Text(
      props.theme.widget.sectionPeek.textStyle.entry,
      props.theme.widget.sectionPeek.textStyle.style,
    )};
  text-align: ${(props) => props.alignment};
  ${(props) =>
    props.subtitle
      ? `margin-bottom: ${spacing(1)};`
      : `margin-bottom: ${spacing(3)};
        ${mq(props.theme.breakpointUtils.map, 'md')} { 
          margin-bottom: ${spacing(4)}
        }`}
`

export const Subtitle = styled.p<{
  size?: TextEntry
  alignment: HorizontalAlignment
}>`
  ${(props) => Text(props.size ?? 'bodyText', 'alternate')};
  text-align: ${(props) => props.alignment};

  margin-bottom: ${spacing(3)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-bottom: ${spacing(4)};
  } ;
`

export const ButtonWrapper = styled(GridItem)`
  margin: ${spacing(2)} 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${spacing(1)} auto;
  }
`

export const ViewMoreButton = styled(Button)`
  margin-top: ${(props) =>
    props.theme.widget.bestSellers.viewMoreButton?.margin.top
      ? spacing(props.theme.widget.bestSellers.viewMoreButton.margin.top)
      : 0};
  width: 100%;
  ${Text('xsmall', 'alternate')};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: auto;
  }
`
